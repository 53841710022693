@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .my-custom-style {
    --theme-primary: #1f7983;
  }
}

html {
  color: #232c39;
}

body {
  margin: 0;
  font-family: "Tajawal", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7fe;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.fullscreen {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* extra fullscreen guarantees - you might not need the rest */
  position: absolute;
  top: 0;
  left: 0;
}

/* .react-datepicker{
  right: 0px;
} */

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker__tab-loop {
  position: relative;
}

.apexcharts-legend-marker {
  left: 5px !important;
}

.apexcharts-legend-text {
  font-family: "Tajawal", sans-serif !important;
  font-size: 14px !important;
}

/* .apexcharts-xaxis-label tspan {
transform: rotate(-6deg) !important;
} */

.react-datepicker-popper {
  /* position: relative !important; */
  inset: 0px !important;
  transform: none !important;
  z-index: 10000 !important;
}

.ant-picker-range-wrapper {
  justify-content: flex-end;
}

[type="checkbox"]:checked + label .label-check {
  display: block !important;
}

[type="checkbox"]:checked + label .add {
  color: var(--theme-primary);
}

[type="checkbox"]:checked + label .remove {
  color: #df4242;
}

[type="checkbox"]:checked + label .label-component .add {
  color: var(--theme-primary);
}

[type="checkbox"]:checked + label .label-component .remove {
  color: #df4242;
}

/* [type="checkbox"] + label .label-box {
  transition: all 0.2s;
} */

/* [type="checkbox"]:enabled + label:hover .label-box .add {
  border: 1px solid var(--theme-primary);
}

[type="checkbox"]:enabled + label:hover .label-box .remove {
  border: 1px solid #df4242;
} */

.block-picker {
  box-shadow: none !important;
}

.block-picker > div:not(:last-child) {
  display: none !important;
}

.block-picker > div:last-child > div:last-child {
  display: none !important;
}

.ant-collapse .ant-collapse-item {
  border-bottom: 1px solid #dfe3ed !important;
}

.ant-collapse .ant-collapse-item-active {
  background-color: #f4f7fe !important;
}

.ant-collapse .ant-collapse-content-active {
  background-color: #f4f7fe !important;
}

.ant-collapse .ant-collapse-content {
  border: none !important;
}

.ant-collapse-header {
  padding: 0 !important;
}

.ant-collapse-expand-icon {
  height: 100% !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: transparent;
}

.ant-picker-dropdown {
  z-index: 1350;
}
